.bulletin-board {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.bulletin-title {
    text-align: center;
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
}

.bulletin-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.bulletin-post {
    background: rgba(255, 255, 255, 0.15);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
}

.bulletin-post:hover {
    transform: scale(1.02);
    background: rgba(255, 255, 255, 0.2);
}

.bulletin-post h4 {
    color: #fff;
    margin-bottom: 5px;
}

.bulletin-post p {
    color: #ccc;
    font-size: 14px;
    margin-bottom: 8px;
}

.bulletin-date {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
    display: block;
    text-align: right;
}
