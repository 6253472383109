.writeup-review-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: black;
    width: 100%;
    max-width: 1000px;
}

.writeup-review-container h3 {
    font-size: 20px;
    color: #ffcc00;
    text-align: center;
    margin-bottom: 15px;
}

.writeup-scroll {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding-bottom: 10px;
    text-align: center;
    align-items: center;
}

.writeup-card {
    min-width: 280px;
    background: rgba(255, 255, 255, 0.15);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: transform 0.2s ease-in-out, background 0.3s;
    text-align: center;
}

.writeup-card:hover {
    transform: scale(1.02);
    background: rgba(255, 255, 255, 0.2);
}

.writeup-card p {
    margin: 0;
    color: black;
}

.view-button {
    background: #ffcc00;
    color: black;
    padding: 8px;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none;
}

.view-button:hover {
    background: #e6b800;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.actions button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.actions button:first-child {
    background: #28a745;
    color: black;
}

.actions button:first-child:hover {
    background: #218838;
    transform: scale(1.05);
}

.actions button:last-child {
    background: rgba(255, 0, 0, 0.7);
    color: black;
}

.actions button:last-child:hover {
    background: rgba(200, 0, 0, 0.9);
    transform: scale(1.05);
}