.SC
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
}

.Container
{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 80%;
    gap: 20px;
    margin: 0 auto;
}

.H2
{
    color: white;
    font-size: 32px;
    margin-top: 50px;
}

.H2Main
{
    color: white;
    font-size: 32px;
    margin-top: 50px;
}

.LongContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #1f2937;
    height: 20vh;
    border-radius: 20px;   
}

.CAForm
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 80%;
    height: 50%;
}

.CAForm input
{
    color: white;
    width: 100%;
    height: 50%;
    background-color: #364051;

    border: none;
    
}

.CAForm button
{
    background-color: #eb590c;
    height: 40%;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.CAForm button:hover 
{
    background-color: #8c3506;
}

.Deploy
{
    background-color: #364051;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
}

.Deploy:hover
{
    cursor: pointer;
    background-color: #687a9a;
}

.Row
{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@media(max-width: 900px)
{
    .H2
    {
        font-size: 20px;
    }
}