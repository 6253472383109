/* General Container */
.task-review-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Header */
.task-review-container h3 {
    color: black;
    font-size: 20px;
    margin-bottom: 15px;
}

/* No Submissions */
.no-submissions {
    color: #bbb;
    font-size: 16px;
}

/* Scrollable Container */
.submissions-scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding: 10px;
    white-space: nowrap;
    max-width: 100%;
    scroll-behavior: smooth;
}

/* Submission Card */
.submission-card {
    min-width: 300px;
    max-width: 350px;
    padding: 15px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.submission-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

/* Submission Details */
.submission-meta {
    font-size: 14px;
    color: #555;
}

/* Submission Link */
.submission-card a {
    color: #ff6600;
    text-decoration: none;
    font-weight: bold;
}

.submission-card a:hover {
    text-decoration: underline;
}

/* Button Actions */
.submission-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.submission-actions button {
    flex: 1;
    padding: 8px 12px;
    border: none;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.submission-actions button:first-child {
    background: #28a745;
    color: white;
}

.submission-actions button:first-child:hover {
    background: #218838;
    transform: scale(1.05);
}

.reject-btn {
    background: rgba(255, 0, 0, 0.7);
    color: white;
}

.reject-btn:hover {
    background: rgba(200, 0, 0, 0.9);
    transform: scale(1.05);
}

/* Scrollbar Styling */
.submissions-scroll-container::-webkit-scrollbar {
    height: 8px;
}

.submissions-scroll-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.submissions-scroll-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}