.PC
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.Container {
    display: flex;
    flex-direction: row;
    gap: 80px;
    width: 80%;
    margin: auto; 
    justify-content: center;
    align-items: center;
}

.Header
{
    color: rgb(255, 255, 255);
    margin-top: 100px; 
    font-size: 34px;
}

.Cont
{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 25%;
}

.Cont h2
{
    color: rgb(215, 215, 215);
}

.Cont p
{
    margin: 0;
    color: rgb(167, 167, 167);
    max-width: 200px;
    text-align: center;
}

.R2
{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    justify-content: center;
}

@media(max-width: 900px)
{
    .Container{
        flex-direction: column;

        gap: 20px;
    }

    .Header
    {
        color: white;
        margin-top: 100px; 
        font-size: 28px;

        max-width: 90%;
    }

    .R2
    {
        flex-direction: column;
        gap: 0;
    }

    .R2 button
    {
        margin-top: 40px;
    }

    .Cont
    {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
    }
}