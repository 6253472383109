.TextContainer
{
    display: flex;
    position: absolute;
    align-items: center;
    text-align: center;
    justify-content: center;

    z-index: 10;
    width: 100vw;
    top: 8.5%;

    gap: 0px;
    font-size: 12px;

}

.TextCol
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HomeTitle
{
    margin: 0;
    color: white;
    opacity: 0;
    font-size: 7em;
    width: 100vw;
    text-align: center;
}

.Tagline
{
    color: rgb(181, 181, 181);
    opacity: 0;
    font-weight: bolder;
    white-space: normal;
    word-break: normal;
    margin: 0;
    width: 35vw;

    text-align: center;

    font-size: 1.75em;
}

.DenText
{
    color: grey; 
    opacity: 0; 
    font-size: 1.7em;
    font-weight: bolder;
    margin: 0;
    width: 28vw;
}

.BenefitsContainer
{
    pointer-events: none;

    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: 10px;
    right: 50%;
    transform: translateX(50%);
    bottom: 4%;
    color: rgb(177, 177, 177);
    z-index: 100000;
    line-height: 1.5;
    gap: 15px;
}

.BenefitsRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.TTRow 
{
    display: flex;
    align-items: center; 
    justify-content: flex-start; 
    gap: 4px; 
    opacity: 0;
}

.TTIcon 
{
    width: 20px;
    text-align: center; 
    flex-shrink: 0;
    color: white;
}

.TTRow h2 
{
    white-space: nowrap;
    margin: 0;
    padding-left: 5px; 
    color: rgb(160, 160, 160);
}

.BenefitsContainer h2
{
    margin: 0;
    padding: 0;
}

@media(max-width: 575px) /* xs */
{
    .HomeTitle
    {
        margin-top: 3vh;
        font-size: 4em;
    }
    .Tagline
    {
        width: 70vw;
        font-size: 1.25em;
    }
}
@media (min-width: 576px) and (max-width: 767px) /* s */
{
    .HomeTitle
    {
        font-size: 6em;
    }
    .Tagline
    {
        width: 60vw;
        font-size: 1.5em;
    }
}

@media(max-width: 704px)
{
    .BenefitsContainer
    {
        display: none;
    }
}

@media(min-width: 704px) and (max-width: 865px)
{
    .BenefitsContainer
    {
        font-size: 7.5px;
    }
}

@media(min-width: 768px) and (max-width: 999px) /* m */
{
    .HomeTitle
    {
        font-size: 6em;
    }
    .Tagline
    {
        width: 50vw;
        font-size: 1.5em;
    }

}
@media(min-width: 1000px) and (max-width: 1200px)
{
    .HomeTitle
    {
        font-size: 6em;
    }
    .Tagline
    {
        width: 50vw;
        font-size: 1.5em;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) /* l */
{
    .HomeTitle
    {
        font-size: 6em;
    }
    .Tagline
    {
        font-size: 1.5em;
    }

}
