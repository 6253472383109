.SidebarItemsContainer
{
    height: 120vh; 
    width: 100%;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
    gap: 0;
}

.SidebarItemsContainerMobile
{
    position: fixed;
    background-color: rgb(50, 50, 50);
    height: 100%; 
    width: 100%;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.Exit
{
    position: absolute;
    left: 0%;

    margin-left: 20px;
    margin-top: 20px;

    width: 30px;
}

.CloseSidebarButton
{   
    margin-top: 20px;
    border: none;
    background-color: rgb(95, 95, 95);
    width: 150px;
    font-size: 16px;
    border-radius: 40px;
    color: white;
}
.CloseSidebarButton:hover
{
    cursor: pointer;
    background-color: rgb(204, 204, 204);
}

.BottomContainer
{
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgb(28, 28, 28);
}

.BottomContainer h2
{
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.BottomContainer h2:hover
{
    color: rgb(174, 174, 174);
    cursor: pointer;
}

.BottomContainer img 
{
    width: 50px;
    height: 50px;
    cursor: pointer;
}