
.Container
{
    width: 100vw;
    height: auto;
    padding-bottom: 40px;
    padding-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(57,57,57,1) 100%);

    min-height: 100vh;
}

.H2
{
    color: white;
    font-size: 40px;
}

.H3
{
    margin: 0;
    color: rgb(187, 187, 187);
    font-size: 22px;
    text-align: center;
}

.H3H:hover{
    color: rgb(232, 232, 232);
}

.Home
{
    cursor: pointer;
}

.Home:hover
{
    color: rgb(138, 138, 138);
}

.PGX
{
    color: rgb(187, 187, 187);
    margin: 0;
    text-align: center;
    font-size: 24px;
}

.PG
{
    color: rgb(187, 187, 187);
    margin: 0;
    width: 60%;
    text-align: center;
    font-size: 24px;

    margin-top: -20px;
}

.PG2
{
    color: rgb(187, 187, 187);
    margin: 0;
    width: 60%;
    text-align: center;
    font-size: 18px;

    margin-top: -40px;
}

.LoginButton
{
    display: flex;
    flex-direction: row;
    gap: 10px;

    align-items: center;
    justify-content: center;

    font-size: 20px;
    padding: 20px;
    background-color: rgb(73, 161, 255);
    border: 1px solid rgb(99, 99, 99);
    cursor: pointer;

    margin-top: 80px;
}

.LoginButton:hover
{
    background-color: rgb(142, 197, 255);
}

.TasksAndProfile
{
    padding: 20px;
    background-color: #797979;
    margin-top: 40px;
    border: 1px solid white;
    cursor: pointer;
    color: white;
}

.TasksAndProfile:hover
{
    background-color: #afafaf;
}

.HomeContainer
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    text-align: left;
    position: absolute;
    left :0;
    margin-left: 20px;
    margin-top: 20px;
    z-index: 100000;
}

.Image
{
    height: 75%;
    width: 60%;
    margin: 0;
}

.Image3
{
    height: 125px;
    width: 125px;
    margin: 0;
}

.Row
{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.Row2 {
    position: relative;
    display: flex;
    width: 40%;
    gap: 60px;
    align-items: center; /* This ensures the number and content are aligned */
    justify-content: center;
    margin: 0;
    margin-top: 40px;
}

.Button {
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: linear-gradient(90deg, orange 0%, rgb(255, 170, 50) 100%);
    color: black;
    padding: 12px 20px; /* More padding for better click area */
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 5px rgba(255, 165, 0, 0.5);
    margin-top: -20px;

    min-width: 160px;
}

.Button:hover {
    background: linear-gradient(90deg, rgb(255, 170, 50) 0%, orange 100%);
    transform: scale(1.1);
    box-shadow: 4px 4px 10px rgba(255, 165, 0, 0.7);
}

.Col
{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.Col2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 25px;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 40px;
    width: 320px; 
}

.Col2 label {
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.Col2 input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    text-align: center;
    transition: background 0.3s ease;
}

.Col2 input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.Col2 input:focus {
    background: rgba(255, 255, 255, 0.3);
    outline: none;
}

.Col2 button {
    background: linear-gradient(90deg, orange 0%, rgb(255, 170, 50) 100%);
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 8px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 5px rgba(255, 165, 0, 0.5);
    width: 100%;
}

.Col2 button:hover {
    background: linear-gradient(90deg, rgb(255, 170, 50) 0%, orange 100%);
    transform: scale(1.05);
    box-shadow: 4px 4px 10px rgba(255, 165, 0, 0.7);
}

.Box
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 15vw;
    height: 20vh;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.Gold
{
    background: rgb(255,221,112) !important;
    background: radial-gradient(circle, rgba(255,221,112,1) 0%, rgba(156,128,42,1) 100%) !important;
} 

.Gold .H3
{
    color: black !important;
}

.Gold:hover
{
    background: rgb(255, 210, 64) !important;
    background: radial-gradient(circle, rgba(255, 210, 64,1) 0%, rgb(102, 83, 27) 100%) !important;
}

.Box:hover
{
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.BoxContainer
{
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: flex-end;

    padding-right: 50px;
    gap: 20px;
    top: 2.5%;
    width: 100%;
    z-index: 1000;
}

.UserBox
{
    cursor: pointer;
}

.UserBox img
{
    width: 75px;
    height: 75px;
}

.SprintBox
{
    height: 50px;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;

    padding-left: 10px;
    padding-right: 10px;
    
    background-color: #004bac;
    border: 2px solid grey;
}

@media(max-width: 555px)
{
    .UserBox
    {
        position: fixed;
        right: 2.5%;
        top: 90%;
    
        cursor: pointer;
    }

    .H3
    {
        margin: 0;
        color: rgb(187, 187, 187);
        font-size: 18px;
    }
    .H2
    {
        text-align: center;
        color: white;
        font-size: 30px;
    }

    .WidthAdjust
    {
        width: 90%;
    }

    .Row
    {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .Container
    {
        height: auto;
        width: 100vw;
        padding-bottom: 40px;
        padding-top: 40px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .HomeContainer {
        display: flex;
        flex-direction: row;
        overflow-x: auto; /* Enables scrolling only when needed */
        gap: 20px;
        position: absolute;
        text-align: left;
        width: 100%; /* Ensures the container takes full available width */
        white-space: nowrap; /* Prevents flex items from wrapping */
        padding-bottom: 5px; /* Prevents content from being cut off by scrollbar */
        margin: 0;
    }
    
    /* Fix for last item getting clipped */
    .HomeContainer > *:last-child {
        margin-right: 20px; /* Ensure last element is fully visible */
    }

    /* Optional: Custom scrollbar styles */
    .HomeContainer::-webkit-scrollbar {
        height: 5px; /* Adjust scrollbar height */
    }

    .HomeContainer::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
    }
    .PG
    {
        color: rgb(187, 187, 187);
        margin: 0;
        text-align: center;
        font-size: 16px;
        width: 80%;
    }
    .PGX
    {
        font-size: 16px;
    }

    .Box
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60vw;
        height: 15vh;
    }

    .Button 
    {
        font-size: 20px;
        cursor: pointer;
        border: none;
        background: linear-gradient(90deg, orange 0%, rgb(255, 170, 50) 100%);
        color: black;
        padding: 12px 20px; /* More padding for better click area */
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        box-shadow: 2px 2px 5px rgba(255, 165, 0, 0.5);
        margin-top: -20px;
    }

    .AddMargin
    {
        margin-top: -30px;
        text-align: center;
    }

}

@media(min-width: 555px) and (max-width: 900px)
{
    .AddMargin
    {
        margin-top: -30px;
        text-align: center;
    }
    .WidthAdjust
    {
        width: 90%;
    }

    .UserBox
    {
        position: fixed;
        right: 2.5%;
        top: 90%;
    
        cursor: pointer;
    }

    .H3
    {
        margin: 0;
        color: rgb(187, 187, 187);
        font-size: 18px;
    }
    .H2
    {
        text-align: center;
        color: white;
        font-size: 30px;
    }

    .Row
    {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .Container
    {
        height: auto;
        width: 100vw;
        padding-bottom: 40px;
        padding-top: 40px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .HomeContainer {
        display: flex;
        flex-direction: row;
        overflow-x: auto; /* Enables scrolling only when needed */
        gap: 20px;
        position: absolute;
        text-align: left;
        width: 100%; /* Ensures the container takes full available width */
        white-space: nowrap; /* Prevents flex items from wrapping */
        padding-bottom: 5px; /* Prevents content from being cut off by scrollbar */
        margin: 0;
    }
    
    /* Fix for last item getting clipped */
    .HomeContainer > *:last-child {
        margin-right: 20px; /* Ensure last element is fully visible */
    }

    /* Optional: Custom scrollbar styles */
    .HomeContainer::-webkit-scrollbar {
        height: 5px; /* Adjust scrollbar height */
    }

    .HomeContainer::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
    }
    .PG
    {
        color: rgb(187, 187, 187);
        margin: 0;
        text-align: center;
        font-size: 16px;
        width: 80%;
    }

    .PGX
    {
        font-size: 16px;
    }

    .Box
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60vw;
        height: 20vh;
    }

    .Button {
        font-size: 14px;
        cursor: pointer;
        border: none;
        background: linear-gradient(90deg, orange 0%, rgb(255, 170, 50) 100%);
        color: black;
        padding: 12px 20px; /* More padding for better click area */
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        box-shadow: 2px 2px 5px rgba(255, 165, 0, 0.5);
        margin-top: -20px;

    }
    

}