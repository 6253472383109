.Row
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Row2 {
    position: relative;
    display: flex;
    width: 80%;
    gap: 60px;
    align-items: center; /* This ensures the number and content are aligned */
    justify-content: center;
    margin: 0;
    margin-top: 40px;
}

.Image2
{
    height: 15%;
    width: 30%;
    margin: 0;
    margin-top: 40px;
}

.Selected
{
    background: white !important; 
    box-shadow: 2px 2px 5px white;
}

.Hidden
{
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 0;
}

.Selected:hover {
    background: white;
    transform: scale(1.1);
    box-shadow: 4px 4px 10px white;
}

.H2
{
    color: white;
}

.ContentContainer
{
    margin: 0;
    padding: 0;
    padding: 40px;
    background-color: rgb(45, 45, 45);
    margin-top: 0px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 40px;
}


@media (max-width: 900px)
{
    .Image2
    {
        height: 150px;
        width: 300px;
        margin: 0;
        margin-top: 40px;
    }

    .Spacer
    {
        margin-top: 60px;
    }

    
    .Row2 
    {
        position: relative;
        display: flex;
        flex-direction: column;

        width: 80%;
        gap: 60px;
        align-items: center; /* This ensures the number and content are aligned */
        justify-content: center;
        margin: 0;
        margin-top: 40px;
    }

}