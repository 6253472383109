.Container {
    display: flex;
    flex-direction: row;
    align-items: center;

    overflow-x: auto; /* Allows scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */

    gap: 20px;
    width: 70%;
    height: 400px;
    padding: 20px;
    margin: 0;
    margin-top: -50px;

    background-color: none;

}

.HR
{
    width: 80%;
    border: none;
    height: 0.5px;
    background-color: rgb(69, 69, 69);
}

.Container > * {
    flex-shrink: 0; /* Prevent items from shrinking */
}
.Row {
    display: flex;
    flex-direction: row;
    width: 80%;
    overflow-x: auto; /* Allows scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
    align-items: center;
    justify-content: center; /* Aligns items from the start instead of centering */
    gap: 20px;
    padding-left: 20px; /* Adds space so the first item isn't clipped */
}

.Row > * {
    flex-shrink: 0; /* Prevents items from shrinking */
    min-width: max-content; /* Ensures they take up necessary space */
}

.Link
{
    color: yellow;
    cursor: pointer;
}

.Link:hover
{
    color: rgb(125, 125, 2);
}

.MicroTask
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 80%;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.MTP
{
    color: white;
    width: 90%;
}

@media(max-width: 670px)
{
    .Row {

        justify-content: flex-start;
    }
    
}