/* Centered Layout */
.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    background-color: #121212;
    width: 100vw;
    gap: 20px;
    z-index: 100;
    padding: 20px;

    display: none;
}

/* Styled Headings */
.Container h2 {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
}

/* Sections for Info & Actions */
.OptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 30px;
    width: 50%;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.OptionContainer p
{
    color: white;
}

.OptionContainer form
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

/* Input Fields */
.OptionContainer input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    outline: none;
    transition: 0.3s;
}

.OptionContainer input:focus {
    border-color: #ffcc00;
}

/* Buttons */
.OptionContainer button {
    padding: 10px 15px;
    background: #ffcc00;
    color: black;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
}

.OptionContainer button:hover {
    background: #e6b800;
    transform: scale(1.05);
}

/* Leave Button */
.Leave {
    color: yellow;
    cursor: pointer;
    font-size: 22px;
    transition: color 0.2s;
}

.Leave:hover {
    color: rgb(200, 200, 0);
}

/* Contributors Section */
.ContributorsContainer {
    width: 90%;
    max-width: 1200px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

/* Header Styling */
.ContributorsHeading {
    color: white;
    text-align: center;
    font-size: 20px;
    margin-bottom: 15px;
}

/* Horizontal Scroll Container */
.ContributorsScroll {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    max-width: 100%;
    scroll-behavior: smooth;
}

/* Styling Scrollbar for WebKit Browsers */
.ContributorsScroll::-webkit-scrollbar {
    height: 8px;
}

.ContributorsScroll::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
}

.ContributorsScroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

/* Individual Contributor Card */
.ContributorCard {
    min-width: 160px; /* Ensure each card is properly spaced */
    padding: 10px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
    flex-shrink: 0; /* Prevents items from shrinking */
}

.ContributorCard:hover {
    transform: scale(1.05);
}

/* Contributor Name */
.ContributorName {
    color: white;
    font-weight: bold;
    font-size: 16px;
    word-break: break-word;
}


.InviteScrollContainer {
    width: 90%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 15px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.InviteRow {
    display: flex;
    gap: 15px;
}

.InviteCard {
    min-width: 200px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    text-align: center;
}

.InviteCard button {
    margin-top: 10px;
}
.progressContainer {
    width: 90%;
    height: 15px;
    background-color: #222;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px auto;
    border: 1px solid #444;
    position: relative;
}

.progressBar {
    height: 100%;
    width: 0;
    border-radius: 10px;
    background: linear-gradient(90deg, #ffcc00, #ff6600, #ff3300);
    background-size: 300% 100%; /* Extends the gradient to create a shifting effect */
    animation: waveGlow 2s infinite linear, shimmer 4s infinite linear;
    transition: width 0.2s ease-in-out;
}

/* Glowing pulsation effect */
@keyframes waveGlow {
    0% {
        box-shadow: 0 0 5px rgba(255, 200, 50, 0.5);
    }
    50% {
        box-shadow: 0 0 15px rgba(255, 100, 50, 0.8);
    }
    100% {
        box-shadow: 0 0 5px rgba(255, 200, 50, 0.5);
    }
}

/* Shimmering movement effect */
@keyframes shimmer {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Container for the Past Tasks */
.PastTasksContainer {
    display: flex;
    flex-direction: row; /* Arrange items in a row */
    gap: 20px; /* Adds spacing between items */
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents items from wrapping */
    padding: 10px;
    max-width: 100%; /* Ensures it doesn't exceed parent container */
}

/* Individual Past Task Item */
.PastTaskItem {
    min-width: 250px; /* Ensures each task has enough space */
    padding: 10px;
    background: rgba(255, 255, 255, 0.1); /* Light background */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    color: white; /* Text color */
    flex-shrink: 0; /* Prevents items from shrinking */
    white-space: normal; /* Allows text wrapping inside items */
}


@media(max-width: 900px)
{
    /* Styled Headings */
    .Container h2 {
        font-size: 18px;
        word-wrap: break-word; 
        overflow-wrap: break-word; 
        white-space: normal;
    }

    /* Sections for Info & Actions */
    .OptionContainer {
        font-size: 18px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        max-width: 100%; /* Ensure text doesn't overflow */
        display: block; /* Ensure the text behaves like a block element */
    }

    .OptionContainer p
    {
        color: white;
    }

    .OptionContainer form
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    /* Input Fields */
    .OptionContainer input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        outline: none;
        transition: 0.3s;
    }

    .OptionContainer input:focus {
        border-color: #ffcc00;
    }

    /* Buttons */
    .OptionContainer button {
        padding: 10px 15px;
        background: #ffcc00;
        color: black;
        font-weight: bold;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s;
    }

    .OptionContainer button:hover {
        background: #e6b800;
        transform: scale(1.05);
    }

    /* Leave Button */
    .Leave {
        color: yellow;
        cursor: pointer;
        font-size: 22px;
        transition: color 0.2s;
    }

    .Leave:hover {
        color: rgb(200, 200, 0);
    }

    .InviteScrollContainer {
        width: 90%;
        overflow-x: auto;
        white-space: nowrap;
        padding: 15px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 16px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    }

    .InviteRow {
        display: flex;
        gap: 15px;
    }

    .InviteCard {
        min-width: 200px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        text-align: center;
    }

    .InviteCard button {
        margin-top: 10px;
    }
}