/* General Container */
.admin-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

h2, h3 {
    text-align: center;
    color: #333;
}

/* Task Form */
.task-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.task-form input, .task-form textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.task-form button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.task-form button:hover {
    background: #0056b3;
}

/* Horizontal Scrolling Task List */
.task-scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding: 10px;
    white-space: nowrap;
    max-width: 100%;
    scroll-behavior: smooth;
}

/* Task Card */
.task-card {
    min-width: 250px;
    max-width: 300px;
    padding: 15px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.task-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.task-description {
    font-size: 14px;
    color: #666;
    max-height: 2.8em; /* Approx. 2 lines */
    overflow-y: auto;
    word-break: break-word;
    display: block;
    line-height: 1.4em; /* Adjust to match typical line height */
    padding-right: 5px; /* Prevents text from touching scrollbar */
}

/* Scrollbar Styling for WebKit Browsers */
.task-description::-webkit-scrollbar {
    width: 5px;
}

.task-description::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.task-description::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

/* Firefox Scrollbar */
.task-description {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.task-meta {
    font-size: 13px;
    color: #444;
}

.task-delete {
    background: red;
    color: white;
    border: none;
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.task-delete:hover {
    background: darkred;
}

/* Scrollbar Styling */
.task-scroll-container::-webkit-scrollbar {
    height: 8px;
}

.task-scroll-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.task-scroll-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

/* Dropdown Label */
.task-filter-label {
    display: block;
    color: black;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 40px;
}

/* Dropdown Styling */
.task-filter-dropdown {
    width: 200px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    cursor: pointer;
}

.task-filter-dropdown:hover {
    background: #f0f0f0;
}