/* Task Item Styling - Glassmorphism */
.task-item {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    transition: transform 0.2s ease-in-out, background 0.3s;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;

    max-width: 600px;
}

.task-h3 {
    color: white;
    max-width: 500px; /* Adjust max width as needed */
    white-space: nowrap; /* Prevents wrapping */
    overflow-x: auto; /* Enables horizontal scrolling */
    display: block; /* Ensures it behaves like a block element */
}

/* Style the scrollbar for better visibility */
.task-h3::-webkit-scrollbar {
    height: 5px;
}

.task-h3::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
}

.task-h3::-webkit-scrollbar-track {
    background: transparent;
}

.task-item:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.2);
}

.desc {
    max-height: 3em; /* Adjust as needed for approximately 2 lines */
    overflow-y: auto; /* Enable vertical scrolling */
    word-wrap: break-word;
    white-space: normal;
    padding-right: 5px; /* Prevent text from touching scrollbar */
}

/* Optional: Style scrollbar for a cleaner look */
.desc::-webkit-scrollbar {
    width: 5px;
}

.desc::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
}

.desc::-webkit-scrollbar-track {
    background: transparent;
}
.points
{
    color: rgb(176, 176, 176);
}

.task-meta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    color: #ddd;
    margin-top: 5px;

}

/* Default Claim Button */
.task-item button {
    margin-top: 10px;
    padding: 8px 12px;
    border: none;
    background: rgba(255, 204, 0, 0.8);
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.task-item button:hover {
    background: rgba(255, 204, 0, 1);
    transform: scale(1.1);
}

/* Red Button - User Already Claimed a Task */
.task-item button.claimed-task {
    background: rgba(255, 50, 50, 0.8);
    color: white;
    cursor: not-allowed;
}

.task-item button.claimed-task:hover {
    background: rgba(200, 40, 40, 0.8);
    transform: none;
}

/* Gray Button - Task is Full */
.task-item button.full-task {
    background: rgba(128, 128, 128, 0.7);
    color: white;
    cursor: not-allowed;
}

.task-item button.full-task:hover {
    background: rgba(100, 100, 100, 0.7);
    transform: none;
}