/* Task List Styling */
.task-list-container {
    max-width: 60%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 50px;
}

.task-list-container h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 15px;
}


.filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px; /* Space between filters */
    flex-wrap: wrap; /* Ensures responsiveness */
}

.group-filter, .subgroup-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: black;
}

.group-filter label, .subgroup-filter label {
    color: #ffffff;
    font-weight: bold;
    white-space: nowrap; /* Ensures labels stay in a single line */
    background-color: black;
}

.group-filter select, .subgroup-filter select {
    background: black;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    min-width: 150px; /* Ensures dropdowns have a minimum width */
}


.task-row {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    padding: 10px 0;
    scroll-behavior: smooth;
    white-space: nowrap;
}

.task-item {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    min-width: 300px;
    flex: 0 0 auto;
    transition: transform 0.2s ease-in-out, background 0.3s;
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.task-item:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.2);
}

.custom-task {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.custom-task h3 {
    color: white;
}

.custom-task input, 
.custom-task textarea {
    width: 100%;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.custom-task button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    background: rgba(255, 204, 0, 0.9);
    color: black;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.custom-task button:hover {
    background: rgba(255, 204, 0, 1);
    transform: scale(1.05);
}

@media(max-width: 900px)
{
    .task-list-container {
        max-width: 80%;
    }
    .task-list-container h2 {

        font-size: 16px;
    }
    .custom-task h3 {
        font-size: 16px;
    }
}

